'use client';

import React from 'react';

import { GetPublicationsReturnItemType } from '@/shared/types';

import { PublicationCard } from '../PublicationCard';

interface PublicationsGridProps {
  publications: GetPublicationsReturnItemType[];
  currentUserEmail: string | null | undefined;
}

export function PublicationsGrid({ publications, currentUserEmail }: PublicationsGridProps) {
  return (
    <section className="grid grid-cols-2 gap-4 pt-6 md:gap-8 lg:grid-cols-3">
      {!!publications?.length &&
        publications.map((publication) => (
          <div key={publication.id} className="flex h-full min-h-[360px] flex-col">
            <PublicationCard publication={publication} currentUserEmail={currentUserEmail} />
          </div>
        ))}
    </section>
  );
}
